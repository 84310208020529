<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">

    

      <div class="row" style="margin-top: 30px">
      <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/33.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  EARTH GROUNDING CORD
                </h4>
                <h5 style="text-align: center">SINT-10XBA</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/34.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  EARTH GROUNDING CORD
                </h4>
                <h5 style="text-align: center">SINT-4X10</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/35.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  GROUNDING CORD FOR WRIST STRAPS
                </h4>
                <h5 style="text-align: center">SINT-10 GSGS</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/36.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  EARTH GROUNDING CORD
                </h4>
                <h5 style="text-align: center">SINT-10-L5X4</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
       
      </div>
      <div class="row" style="margin-top: 30px">
       <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/37.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  EARTH GROUNDING CORD
                </h4>
                <h5 style="text-align: center">SINT-11-2X4XL4</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/38.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  EARTH GROUNDING CORD
                </h4>
                <h5 style="text-align: center">SINT-20-10XBA</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/39.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  EARTH GROUNDING CORD
                </h4>
                <h5 style="text-align: center">SINT-10-L5X10</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/40.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  GROUNDING CORD FOR TABLE MATS
                </h4>
                <h5 style="text-align: center">SINT-20A / SINT-20B</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        
      </div>
      <div class="row" style="margin-top: 30px">
      <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/41.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  EARTH GROUNDING CORD
                </h4>
                <h5 style="text-align: center">SINT-20C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/42.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  GROUNDING CORD FOR TABLE MATS
                </h4>
                <h5 style="text-align: center">SINT-20BY</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/43.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  EARTHING ROD WITH CORD
                </h4>
                <h5 style="text-align: center">SINT-20-GA-GL</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/44.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  BENCH MOUNTED GROUNDER
                </h4>
                <h5 style="text-align: center">SINT-31</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/45.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD WALL MOUNTED GROUNDER
                </h4>
                <h5 style="text-align: center">SINT-32</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/46.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">ADAPTOR</h4>
                <h5 style="text-align: center">SINT-A610</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/cords">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/47.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">SNAP FASTENER
</h4>
                <h5 style="text-align: center">SINT-WL-15</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>